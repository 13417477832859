<template>
  <div class="card p-4 row" style="height: 100%">
    <div class="p-2" style="width: 33%; height: 100%">
      <img :src="require('@/assets/Icons/'+image)" :alt="image" />
    </div>
    <div
      class="p-2 d-flex"
      style="align-items: center; width: 34%; height: 100%"
    >
      <div class="heading ProximaNovaBold" style="text-align: left">
        <h2 class="p-3">{{dataType}}</h2>
        <div
          class="
            text
            ProximaNovaRegular
            font-medium
            pb-3
            pl-3
            pr-3
            pt-0
            lightColorText
          "
        >
          {{text}}
        </div>
      </div>
    </div>
    <div
      class="p-2 d-flex justify-center"
      style="align-items: center; width: 33%; height: 100%"
    >
      <w-button
        :buttonLoader="'normal'"
        :buttonText="'Select'"
        :isDisable="false"
        @buttonClicked="gotoPlatform(dataType)"
      >
      </w-button>
    </div>
  </div>
</template>

<script>
import Button from "@/components/Profile/Button.vue";
export default {
  props: ["dataType", "text", "image"],
  components: {
    "w-button": Button,
  },
  methods: {
    gotoPlatform(e) {
      this.$emit("dataselected", e);
    },
  },
};
</script>

<style scoped>
.card {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-radius: 5px;
}
</style>